import React, { useEffect, useState, ChangeEvent } from 'react';
import {
  Stack,
  Card,
  Button,
  ButtonGroup,
  Flex,
  Select,
  IconButton,
  Box,
  FormControl,
  Pill,
  Text,
  Note
} from '@contentful/f36-components';
import { DeleteIcon } from '@contentful/f36-icons';
import { FieldExtensionSDK } from '@contentful/app-sdk';
import isEqual from 'lodash.isequal';
import { client, gql } from '../services/graphql';
import { completeLoading } from '../Dialogs/Loading';

interface DynamicProductGridReferenceAttributesFieldProps {
  sdk: FieldExtensionSDK;
}
interface ReferenceAttribute {
  id: string;
  key: string;
  values: Array<string>;
}
type CmsProductReference = {
  productId: string;
  productCardId?: string;
};
enum AttributeKey {
  'key' = 'key',
  'values' = 'values'
}
export enum SortDirection {
  MANUAL = 'MANUAL',
  BEST_SELLING = 'BEST_SELLING'
}

// TODO: Later enhancement will refactor to fetch valid attributes from server.
const VALID_ATTRIBUTES = {
  category: [
    'socks',
    't-shirts',
    'underwear',
    'long underwear',
    'slippers',
    'sandals',
    'sweatshirts',
    'sweatpants',
    'bralettes',
    'bags',
    'boxes',
    'giftcard',
    'leggings',
    'gift wrap',
    'hangtag'
  ],
  gender: ['male', 'female', 'unisex', 'na'],
  age: ['adult', 'youth', 'toddler', 'baby', 'na'],
  color: [
    'black',
    'white',
    'grey',
    'blue',
    'beige',
    'red',
    'brown',
    'purple',
    'orange',
    'pink',
    'yellow',
    'green'
  ],
  size: ['xs', 's', 'm', 'l', 'xl', '2xl', '3xl'],
  sock_height: ['no_show', 'ankle', 'quarter', 'calf', 'knee_high'],
  neck_collar_type: ['crew', 'tank', 'v_neck'],
  has_pocket: ['true', 'false'],
  sleeve_length: ['short', 'long'],
  cut: [
    'thong',
    'bikini',
    'hipster',
    'high_rise_hipster',
    'long_underwear',
    'brief',
    'trunk',
    'boxer',
    'boxer_brief'
  ],
  primary_material: ['cotton', 'wool', 'synthetic'],
  subclass: ['casual', 'dress', 'performance', 'hybrid'],
  end_use: ['everyday', 'golf', 'running', 'all_purpose_performance']
};

export interface FieldState {
  sort: SortDirection;
  values: Array<ReferenceAttribute>;
}

const DynamicProductGridReferenceAttributesField = ({
  sdk
}: DynamicProductGridReferenceAttributesFieldProps) => {
  const contentfulValue = sdk.field.getValue();
  const [fieldValue, setFieldValue] = useState<FieldState>(
    (contentfulValue as FieldState) || {
      sort: SortDirection.MANUAL,
      values: []
    }
  );
  const [hasChanges, setHasChanges] = useState(false);
  const setContentfulValue = async (val: {
    sort: SortDirection;
    values: Array<ReferenceAttribute>;
  }) => {
    const sanitizedValues = val.values.filter(
      (v) => v.key !== 'invalid' && v.values.length
    );

    if (sanitizedValues.length) {
      const updated = { sort: val.sort, values: sanitizedValues };

      sdk.dialogs.openCurrentApp({
        width: 400,
        parameters: {
          key: 'loading',
          message: 'Generating product grid'
        }
      });

      client(sdk.ids.environment)
        .request(
          gql`
            query getProductsCmsRef($q: ProductReferenceInput) {
              cmsProductReferences(query: $q) {
                productId
                productCardId
              }
            }
          `,
          {
            q: {
              sort: val.sort,
              attributes: val.values.map((v) => ({
                key: v.key,
                values: v.values
              }))
            }
          }
        )
        .then(
          async (r: { cmsProductReferences: Array<CmsProductReference> }) => {
            await sdk.field.setValue(updated);
            await sdk.entry.fields.items.setValue(
              r.cmsProductReferences.map((p) => ({
                sys: {
                  type: 'Link',
                  linkType: 'Entry',
                  id: p.productCardId || p.productId
                }
              }))
            );
            setHasChanges(false);
            await completeLoading();
          }
        )
        .catch(async () => {
          await completeLoading();

          sdk.dialogs.openAlert({
            title: 'An Error Occurred',
            message:
              'Uh oh, something went wrong. Please contact the Engineering team for support (reference: DynamicProductGrid Product Generation).'
          });
        });
    } else {
      sdk.entry.fields.items.setValue(undefined);
      sdk.field.setValue(undefined);
    }
  };
  const handleAddRow = () => {
    setFieldValue({
      sort: fieldValue.sort,
      values: [
        ...fieldValue.values,
        {
          id: window.crypto.getRandomValues(new Uint32Array(1))[0].toString(16),
          key: 'invalid',
          values: []
        }
      ]
    });
  };
  const handleUpdateRow = (
    rowId: string,
    event: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    setFieldValue((prevState) => {
      const next = prevState.values.map((obj) => {
        if (rowId === obj.id) {
          const key = event.target.name as keyof typeof AttributeKey;

          if (key === 'key') {
            return {
              ...obj,
              key: event.target.value,
              values: []
            };
          }

          return {
            ...obj,
            [key]: Array.from(new Set([...obj[key], event.target.value]))
          };
        }
        return obj;
      });
      return { sort: prevState.sort, values: next };
    });
  };
  const handleDeleteRow = (deleteId: string) =>
    setFieldValue((prevState) => {
      const next = prevState.values.filter(({ id }) => id !== deleteId);
      return { sort: prevState.sort, values: next };
    });
  const handleRemoveValue = (rowId: string, value: string) => {
    setFieldValue((prevState) => {
      const next = prevState.values.map((obj) => {
        if (rowId === obj.id)
          return { ...obj, values: obj.values.filter((v) => v !== value) };
        return obj;
      });
      return { sort: prevState.sort, values: next };
    });
  };
  const handleUpdateSort = (
    event: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    setFieldValue((prevState) => ({
      ...prevState,
      sort: event.target.value as SortDirection
    }));
  };

  useEffect(() => {
    if (!isEqual(contentfulValue, fieldValue)) {
      setHasChanges(true);
    } else {
      setHasChanges(false);
    }
  }, [contentfulValue, fieldValue]);

  // Update height of field whenever new rows are added
  useEffect(() => {
    sdk.window.updateHeight();
  }, [sdk.window, fieldValue.values]);

  return (
    <Stack flexDirection="column" spacing="spacingS" alignItems="left">
      {!fieldValue.values.length && (
        <Note variant="warning">
          No reference attributes have been configured.
        </Note>
      )}
      {fieldValue.values.map(({ id, key, values: rowValues }) => (
        <Card key={id}>
          <Flex
            justifyContent="space-between"
            alignItems="center"
            gap="spacingS"
          >
            <Box style={{ width: '50%' }}>
              <FormControl.Label>Attribute</FormControl.Label>
              <Stack>
                <Box style={{ flex: 1 }}>
                  <Select
                    name="key"
                    value={key}
                    onChange={(e) => handleUpdateRow(id, e)}
                  >
                    <Select.Option value="invalid" isDisabled>
                      Pick an attribute
                    </Select.Option>
                    {Object.keys(VALID_ATTRIBUTES).map((name) => {
                      return (
                        <Select.Option value={name} key={name}>
                          {name}
                        </Select.Option>
                      );
                    })}
                    {key !== 'invalid' &&
                      !Object.keys(VALID_ATTRIBUTES).find((k) => k === key) && (
                        <Select.Option value={key}>
                          {key} (Invalid)
                        </Select.Option>
                      )}
                  </Select>
                </Box>
                <Select
                  name="values"
                  value="invalid"
                  isDisabled={!(key in VALID_ATTRIBUTES)}
                  onChange={(e) => handleUpdateRow(id, e)}
                  style={{ width: '75px' }}
                >
                  <Select.Option value="invalid" isDisabled>
                    Pick
                  </Select.Option>
                  {VALID_ATTRIBUTES[key as keyof typeof VALID_ATTRIBUTES]?.map(
                    (option) => {
                      return (
                        <Select.Option value={option} key={option}>
                          {option}
                        </Select.Option>
                      );
                    }
                  )}
                </Select>
              </Stack>
            </Box>
            <Box style={{ width: '50%' }}>
              <FormControl.Label>Values</FormControl.Label>
              <Stack spacing="spacingXs" flexWrap="wrap">
                {rowValues.length ? (
                  rowValues.map((v) => (
                    <Pill
                      key={v}
                      label={v}
                      onClose={() => handleRemoveValue(id, v)}
                    />
                  ))
                ) : (
                  <Text fontColor="gray500" lineHeight="lineHeight2Xl">
                    No value(s) selected.
                  </Text>
                )}
              </Stack>
            </Box>
            <IconButton
              variant="transparent"
              aria-label="Delete row"
              icon={<DeleteIcon />}
              onClick={() => handleDeleteRow(id)}
            />
          </Flex>
        </Card>
      ))}
      {fieldValue.values.length ? (
        <Card>
          <Stack>
            <Text fontWeight="fontWeightMedium">Product Sort</Text>
            <Select
              name="sort"
              value={fieldValue.sort}
              onChange={handleUpdateSort}
            >
              <Select.Option value={SortDirection.MANUAL}>Manual</Select.Option>
              <Select.Option value={SortDirection.BEST_SELLING}>
                Best Selling
              </Select.Option>
            </Select>
          </Stack>
          <Box paddingTop="spacingS">
            <Text fontColor="gray500">
              {fieldValue.sort === SortDirection.MANUAL
                ? 'Manual sorted grid allows you to customize the order of products and content modules in the grid. New products matching the reference attributes will be automatically added to the end of the item list.'
                : 'Best Selling sorted grid organizes products by best-to-worst selling, while allowing content modules to be placed anywhere in the grid. As products sell, matching product placement will adjust while content modules remain in the same position.'}
            </Text>
          </Box>
        </Card>
      ) : null}
      <ButtonGroup variant="spaced" spacing="spacingS">
        <Button onClick={handleAddRow}>Add item</Button>
        {hasChanges && (
          <Button
            onClick={async () => {
              const result = await sdk.dialogs.openConfirm({
                title: 'Are you sure?',
                message:
                  'This will regenerate ALL items in this grid and cannot be undone.',
                intent: 'negative'
              });
              if (result) setContentfulValue(fieldValue);
            }}
            variant="positive"
          >
            Save Changes
          </Button>
        )}
      </ButtonGroup>
      <Text as="i" fontColor="gray500">
        Reference attributes form the conditions that products must meet to be
        included in the grid.
      </Text>
    </Stack>
  );
};

export default DynamicProductGridReferenceAttributesField;
