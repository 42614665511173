import ReferenceAttributesField from '../ReferenceAttributeInput/ReferenceAttributeInput';
import { FieldExtensionSDK } from '@contentful/app-sdk';
import { Text, Stack } from '@contentful/f36-components';

const FilterOptionReferenceAttributesField = ({
  sdk
}: {
  sdk: FieldExtensionSDK;
}) => {
  // TODO: Later enhancement will refactor to fetch valid attributes from server.
  const VALID_ATTRIBUTES = {
    color: [
      'black',
      'white',
      'grey',
      'blue',
      'beige',
      'red',
      'brown',
      'purple',
      'orange',
      'pink',
      'yellow',
      'green'
    ],
    size: ['xs', 's', 'm', 'l', 'xl', '2xl', '3xl'],
    age: ['adult', 'youth', 'toddler', 'baby', 'na'],
    sock_height: ['no_show', 'ankle', 'quarter', 'calf', 'knee_high'],
    neck_collar_type: ['crew', 'tank', 'v_neck'],
    has_pocket: ['true', 'false'],
    sleeve_length: ['short', 'long'],
    cut: [
      'thong',
      'bikini',
      'hipster',
      'high_rise_hipster',
      'long_underwear',
      'brief',
      'trunk',
      'boxer',
      'boxer_brief'
    ],
    primary_material: ['cotton', 'wool', 'synthetic'],
    subclass: ['casual', 'dress', 'performance', 'hybrid'],
    end_use: ['everyday', 'golf', 'running', 'all_purpose_performance']
  };

  return (
    <Stack flexDirection="column" spacing="spacingS" alignItems="left">
      <ReferenceAttributesField sdk={sdk} attributes={VALID_ATTRIBUTES} />
      <Text fontColor="gray800" fontSize="fontSizeS">
        Reference attributes are used to link a filter option to attributes on a
        product.
      </Text>
      <Text as="i" fontColor="gray500">
        The combination of all rows will make this filter active.
      </Text>
    </Stack>
  );
};

export default FilterOptionReferenceAttributesField;
