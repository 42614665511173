import ReferenceAttributesField from '../ReferenceAttributeInput/ReferenceAttributeInput';
import { FieldExtensionSDK } from '@contentful/app-sdk';
import { Stack, Text } from '@contentful/f36-components';

const BreadcrumbResourceReferenceAttributes = ({
  sdk
}: {
  sdk: FieldExtensionSDK;
}) => {
  // TODO: Later enhancement will refactor to fetch valid attributes from server.
  const VALID_ATTRIBUTES = {
    gender: ['men', 'women', 'kids', 'toddler', 'unisex'],
    category: ['socks', 't-shirts', 'underwear', 'slipper'],
    sock_height: ['no_show', 'ankle', 'quarter', 'calf', 'knee_high'],
    neck_collar_type: ['crew', 'tank', 'v_neck'],
    underwear_cut: [
      'thong',
      'bikini',
      'hipster',
      'high_rise_hipster',
      'long_underwear',
      'brief',
      'trunk',
      'boxer',
      'boxer_brief'
    ]
  };

  return (
    <Stack flexDirection="column" spacing="spacingS" alignItems="left">
      <ReferenceAttributesField sdk={sdk} attributes={VALID_ATTRIBUTES} />
      <Text>
        Reference attributes are used to connect a breadcrumb to attributes on a
        product.
      </Text>
      <Text as="i" fontColor="gray500">
        Include as much information as possible for automatic breadcrumb
        generation to be effective. If the breadcrumb is for a Women's Calf
        Sock, the appropriate tags for gender, category, and sock_height should
        be added. If a breadcrumb is for Women's Socks, the appropriate tags for
        gender and category should be added.
      </Text>
    </Stack>
  );
};

export default BreadcrumbResourceReferenceAttributes;
