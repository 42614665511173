import React from 'react';
import { PlainClientAPI } from 'contentful-management';
import { DialogExtensionSDK } from '@contentful/app-sdk';
import { Heading } from '@contentful/f36-components';

import Loading from './Dialogs/Loading';

interface DialogProps {
  sdk: DialogExtensionSDK;
  cma: PlainClientAPI;
}
interface IDialogMap {
  [k: string]: (_props: DialogProps) => JSX.Element;
}
interface InvocationParams {
  key?: string;
}

/**
 * Mapping of contentTypeId.fieldId to the rendered component
 */
const DialogMap: IDialogMap = {
  loading: Loading
};

const Dialog = ({ sdk, cma }: DialogProps) => {
  const field = (sdk.parameters.invocation as InvocationParams).key || '';
  const Component = DialogMap[field];

  if (Component) {
    return <Component sdk={sdk} cma={cma} />;
  }
  return (
    <Heading>
      ERROR: Unsupported dialog specified.
      <code>{field}</code>
    </Heading>
  );
};

export default Dialog;
