import React, { useEffect, useState, ChangeEvent } from 'react';
import {
  Stack,
  Card,
  Button,
  Flex,
  Select,
  IconButton,
  Box,
  FormControl,
  Pill,
  Text
} from '@contentful/f36-components';
import { DeleteIcon } from '@contentful/f36-icons';
import { FieldExtensionSDK } from '@contentful/app-sdk';

interface ReferenceAttributesInputProps {
  sdk: FieldExtensionSDK;
  attributes: Record<string, string[]>;
}
export interface ReferenceAttribute {
  id: string;
  key: string;
  values: Array<string>;
}
enum AttributeKey {
  'key' = 'key',
  'values' = 'values'
}

// This allows us to use the new field while automatically
// converting previously configured reference attribute fields.
const convertToNewModel = (
  payload: Array<ReferenceAttribute & { value: string }>
) =>
  payload?.map((o) => ({
    id: o.id,
    key: o.key,
    values: o.values || o.value.split(',')
  }));

const ReferenceAttributesInput = ({
  sdk,
  attributes
}: ReferenceAttributesInputProps) => {
  const [values, setValues] = useState<Array<ReferenceAttribute>>(
    convertToNewModel(sdk.field.getValue()) || []
  );
  const setContentfulValue = (val: Array<ReferenceAttribute>) => {
    const sanitizedValues = val.filter(
      (v) => v.key !== 'invalid' && v.values.length
    );

    sdk.field.setValue(sanitizedValues.length ? sanitizedValues : undefined);
  };
  const handleAddRow = () => {
    setValues([
      ...values,
      {
        id: window.crypto.getRandomValues(new Uint32Array(1))[0].toString(16),
        key: 'invalid',
        values: []
      }
    ]);
  };
  const handleUpdateRow = (
    rowId: string,
    event: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    setValues((prevState) => {
      const next = prevState.map((obj) => {
        if (rowId === obj.id) {
          const key = event.target.name as keyof typeof AttributeKey;

          if (key === 'key') {
            return {
              ...obj,
              key: event.target.value,
              values: []
            };
          }

          return {
            ...obj,
            [key]: Array.from(new Set([...obj[key], event.target.value]))
          };
        }
        return obj;
      });
      setContentfulValue(next);
      return next;
    });
  };
  const handleDeleteRow = (deleteId: string) =>
    setValues((prevState) => {
      const next = prevState.filter(({ id }) => id !== deleteId);
      setContentfulValue(next);
      return next;
    });
  const handleRemoveValue = (rowId: string, value: string) => {
    setValues((prevState) => {
      const next = prevState.map((obj) => {
        if (rowId === obj.id)
          return { ...obj, values: obj.values.filter((v) => v !== value) };
        return obj;
      });
      setContentfulValue(next);
      return next;
    });
  };

  // Update height of field whenever new rows are added
  useEffect(() => {
    sdk.window.updateHeight();
  }, [sdk.window, values]);

  return (
    <Stack flexDirection="column" spacing="spacingS" alignItems="left">
      {values.map(({ id, key, values: rowValues }) => (
        <Card key={id}>
          <Flex
            justifyContent="space-between"
            alignItems="center"
            gap="spacingS"
          >
            <Box style={{ width: '50%' }}>
              <FormControl.Label>Attribute</FormControl.Label>
              <Stack>
                <Box style={{ flex: 1 }}>
                  <Select
                    name="key"
                    value={key}
                    onChange={(e) => handleUpdateRow(id, e)}
                  >
                    <Select.Option value="invalid" isDisabled>
                      Pick an attribute
                    </Select.Option>
                    {Object.keys(attributes).map((name) => {
                      return (
                        <Select.Option value={name} key={name}>
                          {name}
                        </Select.Option>
                      );
                    })}
                    {key !== 'invalid' &&
                      !Object.keys(attributes).find((k) => k === key) && (
                        <Select.Option value={key}>
                          {key} (Invalid)
                        </Select.Option>
                      )}
                  </Select>
                </Box>
                <Select
                  name="values"
                  value="invalid"
                  isDisabled={!(key in attributes)}
                  onChange={(e) => handleUpdateRow(id, e)}
                  style={{ width: '75px' }}
                >
                  <Select.Option value="invalid" isDisabled>
                    Pick
                  </Select.Option>
                  {attributes[key as keyof typeof attributes]?.map((option) => {
                    return (
                      <Select.Option value={option} key={option}>
                        {option}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Stack>
            </Box>
            <Box style={{ width: '50%' }}>
              <FormControl.Label>Values</FormControl.Label>
              <Stack spacing="spacingXs" flexWrap="wrap">
                {rowValues.length ? (
                  rowValues.map((v) => (
                    <Pill
                      key={v}
                      label={v}
                      onClose={() => handleRemoveValue(id, v)}
                    />
                  ))
                ) : (
                  <Text fontColor="gray500" lineHeight="lineHeight2Xl">
                    No value(s) selected.
                  </Text>
                )}
              </Stack>
            </Box>
            <IconButton
              variant="transparent"
              aria-label="Delete row"
              icon={<DeleteIcon />}
              onClick={() => handleDeleteRow(id)}
            />
          </Flex>
        </Card>
      ))}
      <Button onClick={handleAddRow}>Add item</Button>
    </Stack>
  );
};

export default ReferenceAttributesInput;
