import React, { useState, useEffect } from 'react';
import {
  MultipleEntryReferenceEditor,
  CombinedLinkActions
} from '@contentful/field-editor-reference';
import { FieldExtensionSDK, ContentType } from '@contentful/app-sdk';
import { Entry } from 'contentful-management';
import { Box, Note } from '@contentful/f36-components';
import {
  FieldState,
  SortDirection
} from './DynamicProductGrid.referenceAttributes';

interface DynamicProductGridItemsFieldProps {
  sdk: FieldExtensionSDK;
}
const getContentTypeIds = (contentTypes: ContentType[]) =>
  contentTypes.map((ct) => ct.sys.id);

const DynamicProductGridItemsField = ({
  sdk
}: DynamicProductGridItemsFieldProps) => {
  const [refAttributeConfig, setRefAttributeConfig] = useState<FieldState>(
    sdk.entry.fields.referenceAttributes.getValue()
  );

  // Listen for changes to `referenceAttributes` field.
  useEffect(() => {
    const unsubscribe = sdk.entry.fields.referenceAttributes.onValueChanged(
      (value) => {
        setRefAttributeConfig(value);
      }
    );

    return () => {
      unsubscribe();
    };
  }, [sdk.entry.fields.referenceAttributes, sdk.field.onValueChanged]);

  sdk.window.startAutoResizer();

  return (
    <>
      {!refAttributeConfig && (
        <Box marginBottom="spacingS">
          <Note variant="neutral">
            You must set reference attributes before adding additional items.
          </Note>
        </Box>
      )}
      <MultipleEntryReferenceEditor
        sdk={sdk}
        viewType="link"
        hasCardEditActions
        hasCardMoveActions={false}
        isInitiallyDisabled
        parameters={{
          instance: { showCreateEntityAction: true, showLinkEntityAction: true }
        }}
        renderCustomActions={(props) => (
          <CombinedLinkActions
            {...props}
            isDisabled={!refAttributeConfig}
            onLinkExisting={async () => {
              if (props.entityType === 'Entry') {
                // Dynamic grids are generated and synchronized based on their configured attributes.
                // This prevents a user from manually adding additional Product & ProductCard to the grid.
                const entity = await sdk.dialogs.selectSingleEntry<Entry>({
                  locale: sdk.field.locale,
                  contentTypes: getContentTypeIds(
                    props.contentTypes.filter(
                      (c) =>
                        c.sys.id !== 'product' && c.sys.id !== 'productCard'
                    )
                  )
                });
                if (entity) {
                  props.onLinkedExisting([entity]);
                }
              }
            }}
            contentTypes={props.contentTypes.filter(
              (c) => c.sys.id !== 'product' && c.sys.id !== 'productCard'
            )}
          />
        )}
        renderCustomCard={(props, _, renderDefaultCard) => {
          return renderDefaultCard({
            ...props,
            // Due to the nature of dynamic grids, Product & ProductCard cannot be removed from the grid
            // unless we introduce a disallow-list mechanism to persist removed cards.
            onRemove:
              props?.contentType?.sys.id === 'product' ||
              props.contentType?.sys.id === 'productCard'
                ? undefined
                : props.onRemove,
            renderDragHandle:
              refAttributeConfig?.sort === SortDirection.BEST_SELLING &&
              (props?.contentType?.sys.id === 'product' ||
                props.contentType?.sys.id === 'productCard')
                ? undefined
                : props.renderDragHandle
          });
        }}
      />
    </>
  );
};

export default DynamicProductGridItemsField;
