import React, { useEffect, useState } from 'react';
import { FieldExtensionSDK } from '@contentful/app-sdk';
import { Button, Spinner, Text } from '@contentful/f36-components';
import { PlainClientAPI } from 'contentful-management';
import { client, gql } from '../services/graphql';
import { SingleEntryReferenceEditor } from '@contentful/field-editor-reference';
import { ReferenceAttribute } from '../ReferenceAttributeInput/ReferenceAttributeInput';

interface ProductBreadcrumbFieldProps {
  sdk: FieldExtensionSDK;
  cma: PlainClientAPI;
}

const DEMOGRAPHIC_SLUG = {
  Adult: {
    Male: 'Men',
    Female: 'Women'
  },
  Youth: {
    Unisex: 'Youth'
  },
  Toddler: {
    Unisex: 'Toddler'
  },
  Baby: {
    Unisex: 'Baby'
  }
} as const;

type BreadcrumbLevels = {
  demographic?: string;
  category?: string;
  subcategory?: string;
};

/**
 * A breadcrumb has 3 different sections- each referred to as a `slug`
 *
 * 1 - Gender
 * 2 - Category
 * 3 - Subcategory
 *
 * @example
 *
 * Women / Sock / Quarter
 * Men / Shirt / Crew
 */
const ProductBreadcrumb = ({ sdk, cma }: ProductBreadcrumbFieldProps) => {
  const [variants, setVariants] = useState(
    sdk.entry.fields.variants.getValue() || []
  );
  const [breadcrumbs, setBreadcrumbs] = useState(
    sdk.entry.fields.breadcrumb.getValue() || undefined
  );
  const [inProgress, setInProgress] = useState(false);

  const REF_ATTRIBUTE_MAP = {
    SOCKS: 'sock_height',
    UNDERWEAR: 'underwear_cut',
    SHIRTS: 'neck_collar_type',
    'T-SHIRTS': 'neck_collar_type'
  } as const;

  const getBreadcrumbEntries = async ({
    demographic,
    category,
    subcategory
  }: BreadcrumbLevels) => {
    const query = {
      content_type: 'breadcrumbResource'
    } as const;

    // Get breadcrumb entries
    const entries = await cma.entry.getMany({
      query
    });

    // Finds the best matching breadcrumb in decreasing level of specificity
    return entries.items.find((e) => {
      const refAttributes: ReferenceAttribute[] =
        e.fields.referenceAttributes?.[sdk.field.locale];

      if (!refAttributes) {
        return undefined;
      }

      const refGender =
        demographic &&
        refAttributes.find(
          ({ key, values }) =>
            key === 'gender' && values.includes(demographic.toLowerCase())
        );

      const refSubcategory =
        refGender &&
        category &&
        subcategory &&
        refAttributes.find(
          ({ key, values }) =>
            category &&
            key ===
              REF_ATTRIBUTE_MAP[
                category.toUpperCase() as keyof typeof REF_ATTRIBUTE_MAP
              ] &&
            values.includes(subcategory.toLowerCase())
        );

      if (refSubcategory) return refSubcategory;

      const refCategory =
        category &&
        refGender &&
        refAttributes.find(
          ({ key, values }) =>
            key === 'category' && values.includes(category.toLowerCase())
        );

      if (refCategory) return refCategory;

      if (refGender) return refGender;

      return undefined;
    });
  };

  // Generates a breadcrumb as long as variants exist on the product and breadcrumbs arent already existing
  const generateBreadcrumb = async () => {
    if (!variants.length) return undefined;
    if (breadcrumbs) return undefined;

    setInProgress(true);

    // Demographic is level 1 breadcrumb
    const marketedAge: keyof typeof DEMOGRAPHIC_SLUG =
      sdk.entry.fields.marketed_age.getValue();
    const marketedGender = sdk.entry.fields.marketed_gender.getValue();
    let demographic: string;

    if (marketedAge === 'Adult') {
      demographic =
        DEMOGRAPHIC_SLUG?.[marketedAge]?.[
          marketedGender as keyof typeof DEMOGRAPHIC_SLUG['Adult']
        ];
    } else {
      demographic = DEMOGRAPHIC_SLUG?.[marketedAge]?.['Unisex'];
    }

    const breadcrumbEntriesParams: BreadcrumbLevels = {};
    breadcrumbEntriesParams.demographic = demographic;

    const res = await cma.entry.get({ entryId: variants[0].sys.id });
    const sku = res.fields.sku[sdk.field.locale];

    const { itemAsVariant } = await client(sdk.ids.environment).request(
      gql`
        query Query($sku: String!) {
          itemAsVariant(sku: $sku)
        }
      `,
      { sku }
    );
    const { components } = itemAsVariant;

    if (components.length) {
      // Category is level 2 breadcrumb
      const { category } = components[0];

      if (category) {
        breadcrumbEntriesParams['category'] = category;

        const detailMap = {
          UNDERWEAR: 'cut',
          SLIPPERS: 'bottom_type',
          SHIRTS: 'sleeve_length',
          'T-SHIRTS': 'sleeve_length',
          SOCKS: 'height'
        } as const;

        // Subcategory is level 3 breadcrumb
        const subcategory =
          components[0][detailMap[category as keyof typeof detailMap]];

        if (subcategory) {
          breadcrumbEntriesParams['subcategory'] = subcategory;
        }
      }
    }

    const entry = await getBreadcrumbEntries(breadcrumbEntriesParams);

    if (entry) {
      sdk.entry.fields.breadcrumb.setValue({
        sys: {
          type: 'Link',
          linkType: 'Entry',
          id: entry.sys.id
        }
      });
    }

    setInProgress(false);
  };

  const handleAction = () => setBreadcrumbs(sdk.field.getValue());

  // Watch fields for changes
  useEffect(() => {
    const unsubscribe = sdk.entry.fields.variants.onValueChanged((vals) => {
      setVariants(vals || []);
    });

    return () => unsubscribe();
  }, [sdk.entry.fields.variants]);

  useEffect(() => {
    sdk.window.updateHeight();
  }, [breadcrumbs, sdk.window]);

  if (inProgress) return <Spinner size="large" />;

  return (
    <div>
      {!variants.length && !breadcrumbs && (
        <Text>
          Add variants to automatically generate breadcrumbs. The automatically
          generated breadcrumb can be updated manually if needed.
        </Text>
      )}
      {!!variants.length && !breadcrumbs && (
        <Button onClick={() => generateBreadcrumb()}>
          Generate Breadcrumbs
        </Button>
      )}
      <SingleEntryReferenceEditor
        viewType="link"
        sdk={sdk}
        hasCardEditActions
        isInitiallyDisabled={true}
        parameters={{
          instance: {
            showCreateEntityAction: true,
            showLinkEntityAction: true
          }
        }}
        onAction={handleAction}
      />
    </div>
  );
};

export default ProductBreadcrumb;
