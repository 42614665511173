import React, { useEffect } from 'react';
import { DialogExtensionSDK } from '@contentful/app-sdk';
import { PlainClientAPI } from 'contentful-management';
import { Stack, Text, Spinner } from '@contentful/f36-components';

interface LoadingDialogProps {
  sdk: DialogExtensionSDK;
  cma: PlainClientAPI;
}
interface InvocationParams {
  message?: string;
}

const FINISH_LOADING_EVENT = '__bombas-FinishLoadingEvent';
const FINISH_LOADING_ACK = '__bombas-FinishLoadingEvent-ACK';

/**
 * Imperative way to close the Loading dialog
 */
export const completeLoading = async () => {
  return new Promise((resolve) => {
    let pollEvent = 0 as unknown as ReturnType<typeof setInterval>;

    const listener = (event: MessageEvent) => {
      if (event.data === FINISH_LOADING_ACK) {
        clearInterval(pollEvent);
        window.removeEventListener('message', listener);
        resolve(void 0);
      }
    };
    window.addEventListener('message', listener, false);

    pollEvent = setInterval(() => {
      const frames = window.parent.frames;
      for (let i = 0; i < frames.length; i++) {
        frames[i].window.postMessage(FINISH_LOADING_EVENT);
      }
    }, 500);
  });
};

const acknowledgeCompleteLoading = () => {
  const frames = window.parent.frames;
  for (let i = 0; i < frames.length; i++) {
    frames[i].window.postMessage(FINISH_LOADING_ACK);
  }
};

const LoadingDialog = ({ sdk }: LoadingDialogProps) => {
  const params = sdk.parameters.invocation as InvocationParams;

  useEffect(() => {
    window.addEventListener(
      'message',
      (event) => {
        if (event.data === FINISH_LOADING_EVENT) {
          acknowledgeCompleteLoading();
          sdk.close();
        }
      },
      false
    );
  }, [sdk]);

  return (
    <Stack
      flexDirection="column"
      style={{ height: '150px' }}
      justifyContent="center"
      alignItems="center"
    >
      <Spinner variant="primary" />
      <Text fontSize="fontSizeL" fontWeight="fontWeightDemiBold">
        {params.message || 'Loading'}
      </Text>
    </Stack>
  );
};

export default LoadingDialog;
