import React from 'react';
import { PlainClientAPI } from 'contentful-management';
import { Heading } from '@contentful/f36-components';
import { SidebarExtensionSDK } from '@contentful/app-sdk';
import VariantSidebar from './Sidebars/Variant';
import PageSidebar from './Sidebars/Page';

interface SidebarProps {
  sdk: SidebarExtensionSDK;
  cma: PlainClientAPI;
}

interface IFieldMap {
  [k: string]: (_props: SidebarProps) => JSX.Element;
}

/**
 * Mapping of contentTypeId.fieldId to the rendered component
 */
 const FieldMap: IFieldMap = {
  'variant': VariantSidebar,
  'page': PageSidebar
};

const Sidebar = ({ sdk, cma }: SidebarProps): React.ReactElement => {
  const key = sdk.contentType.sys.id;
  const Component = FieldMap[key];
  if (Component) {
    return <Component sdk={sdk} cma={cma} />;
  }

  return (
    <Heading>
      ERROR: Product app used in an unsupported sidebar content model: <code>{key}</code>
    </Heading>
  );
};

export default Sidebar;